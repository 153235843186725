import { lazy, Suspense } from 'react';
import { BrowserRouter as Router, Navigate, Route, Routes } from 'react-router-dom';
import { ToasterProvider } from '@sgwt/react-shared-libs';
import { SgwtHelpCenter, SgwtMiniFooter } from '@sgwt/sgwt-widgets-react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ErrorBoundary } from './components/common/ErrorBoundary';
import Header from './components/common/Header';
import { Loading } from './components/common/Loading';
import { ScrollToTop } from './components/common/scroll';
import { useUserRights } from './shared/security';

import './App.scss';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

// Lazy loading of sub-pages. With Vite.js, it creates separate JS bundles for each page,
// which are loaded only on demand when the user go on the corresponding page.
const PageNotFound = lazy(() => import('./components/common/PageNotFound'));
const ExplorePurchaseActs = lazy(() => import('./routes/explore/ExplorePurchaseActs'));
const ExploreSoftwares = lazy(() => import('./routes/explore/ExploreSoftwares'));
const ExplorePurchaseOrders = lazy(() => import('./routes/explore/ExplorePurchaseOrders'));

const ContractPage = lazy(() => import('./routes/contract/ContractPage'));
const ContractCreationPage = lazy(() => import('./routes/contract/ContractCreationPage'));
const SoftwarePage = lazy(() => import('./routes/software/SoftwarePage'));
const SoftwareCreationPage = lazy(() => import('./routes/software/SoftwareCreationPage'));
const AssessmentPage = lazy(() => import('./routes/assessment/AssessmentPage'));
// Admin pages
const Admin = lazy(() => import('./routes/admin/Admin'));
const ContractAdminPage = lazy(() => import('./routes/admin/contract/ContractAdminPage'));
const SoftwareAdminPage = lazy(() => import('./routes/admin/software/SoftwareAdminPage'));
const BlueBookAdminPage = lazy(() => import('./routes/admin/bluebook/BlueBookAdminPage'));
const LogsAdminPage = lazy(() => import('./routes/admin/logs/LogsAdminPage'));
const SourcingHubPage = lazy(() => import('./routes/sourcing-hub/SourcingHubPage'));
const SourcingHubSoftwarePage = lazy(() => import('./routes/sourcing-hub/SourcingHubSoftwarePage'));
const SourcingHubSupplierPage = lazy(() => import('./routes/sourcing-hub/SourcingHubSupplierPage'));
const AribaPage = lazy(() => import('./routes/admin/ariba/AribaPage'));

function RouterProvider() {
  const rights = useUserRights();

  return (
    <Router>
      <QueryClientProvider client={queryClient}>
        <Header />
        <div className="flex-grow-1">
          <Suspense fallback={<Loading />}>
            <ScrollToTop>
              <Routes>
                {/* Catalogue pages */}
                <Route path="/" element={<Navigate to="/explore" replace />} />
                <Route path="/explore" element={<Navigate to="/explore/contracts" replace />} />
                <Route path="/explore">
                  <Route path="/explore/contracts" element={<ExplorePurchaseActs />} />
                  <Route path="/explore/softwares" element={<ExploreSoftwares />} />
                  <Route path="/explore/purchases" element={<ExplorePurchaseOrders />} />
                </Route>

                {rights.assessments.accessToPage && <Route path="/assessments" element={<AssessmentPage />} />}

                {/* Software & Contract pages */}
                <Route path="/software/:id/*" element={<SoftwarePage />} />
                <Route path="/contract/:id/*" element={<ContractPage />} />
                {rights.softwares.creation && <Route path="/software/new" element={<SoftwareCreationPage />} />}
                {rights.contracts.creation && <Route path="/contract/new" element={<ContractCreationPage />} />}
                {/* Administration pages */}
                {rights.roles.administrator && (
                  <>
                    <Route path="/admin" element={<Admin />}>
                      <Route path="/admin" element={<Navigate to="/admin/contracts" replace />} />
                      <Route path="/admin/contracts" element={<ContractAdminPage />} />
                      <Route path="/admin/softwares" element={<SoftwareAdminPage />} />
                      <Route path="/admin/bluebook" element={<BlueBookAdminPage />} />
                      <Route path="/admin/logs" element={<LogsAdminPage />} />
                      <Route path="/admin/sourcing-hub" element={<SourcingHubPage />} />
                      <Route path="/admin/sourcing-hub/software/:id" element={<SourcingHubSoftwarePage />} />
                      <Route path="/admin/sourcing-hub/supplier/:id" element={<SourcingHubSupplierPage />} />
                      <Route path="/admin/ariba" element={<AribaPage />} />
                    </Route>
                  </>
                )}
                <Route path="/not-found" element={<PageNotFound />} />
                <Route path="*" element={<PageNotFound />} />
              </Routes>
            </ScrollToTop>
          </Suspense>
        </div>
        <SgwtHelpCenter applicationId="software-catalogue" mailSubject="[Softwares Catalogue]" messageOnly />
        <SgwtMiniFooter
          type="compact"
          design="light"
          container="container"
          mode="sg-markets"
          contactUsByHelpCenter="sgwt-help-center"
        />
      </QueryClientProvider>
    </Router>
  );
}

const App = () => {
  return (
    <ErrorBoundary>
      <ToasterProvider>
        <RouterProvider />
      </ToasterProvider>
    </ErrorBoundary>
  );
};

export default App;
